<template>

<!-- 学校管理的  年级  新增-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div class="government-container">
              <div class="government-from_section">
                <el-form label-position="right" label-width="100px" :model="basicInfo" ref="basicInfoSRef" :rules="basicInfoSRules">
                  <el-form-item label="年级名称" prop="gradeName">
                    <el-input maxlength="11" v-model="basicInfo.gradeName"></el-input>
                  </el-form-item>
                  <el-form-item label="年级类型" prop="gradeType">
                    <el-select v-model="basicInfo.gradeType" placeholder="请选择">
                      <el-option
                        v-for="item in level"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <div class="button-section">
                  <el-button class="add-button" @click="addSchoolData">提交</el-button>
                  <router-link :to="{name: 'managementSchoolGrade', query: {schoolId: basicInfo.schoolId }}">
                    <el-button class="return-button">取消</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      level: [
        { value: 0, label: '幼儿园' },
        { value: 1, label: '小学' },
        { value: 2, label: '初中' },
        { value: 3, label: '高中' },
        { value: 4, label: '大学' }
      ],
      basicInfoSRules: {
        // 年级名称
        gradeName: [
          { required: true, message: '请输入年级名称', trigger: 'change' }
        ],
        // 年级类型
        gradeType: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ]
      },
      basicInfo: {
        gradeName: null,
        gradeType: null,
        schoolId: null
      }
    }
  },
  created () {
    // this.getData()/
    this.basicInfo.schoolId = this.$route.query.schoolId
  },
  methods: {
    addSchoolData () {
      this.$refs.basicInfoSRef.validate(valid => {
        if (valid) {
          if (!this.basicInfo.schoolId) {
            this.$message({
              type: 'success',
              message: '没有关联学校，请返回!'
            })
            return
          }
          AdministratorService.AdministratorAddNewGrade(this.basicInfo).then(res => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })
            this.$router.push({ name: 'managementSchoolGrade', query: { schoolId: this.basicInfo.schoolId } })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;
  .body-container{
    padding: 80px 40px 20px 61px;
    .body-section{
      min-width: 450px;
    }
    .basic-container{
      max-width: 450px;
      .el-select{
        width: 100%;
      }
    }
    .all-container{
      .all-section-header{
          width: 100%;
          min-width: 160px;
          height: 50px;
          background-color: #F8F8F8;
          padding:  0 30px;
          border-radius: 6px;
          margin-bottom: 40px;
        .header-title{
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }
      .organization-from_section{
        max-width: 450px;
        /deep/.el-icon-camera{
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }
        /deep/.el-upload-dragger{
          width: 350px;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .government-from_section{
        max-width: 450px;
        .el-select{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .school-from_section{
        max-width: 450px;
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .class-from_section{
        max-width: 450px;
        .el-select, .el-autocomplete{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}
/deep/.el-popper, .el-select-dropdown {
  .hover{
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
